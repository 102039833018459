import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import START from "../../images/Start.svg"

const Start = () => (
  <TutorialLayout
    imageSrc={START}
    title="Cum deschidem un container folosind telefonul sau cardul fizic?"
    buttonLink="/salut/second"
    buttonText="Start"
    description="Am pregătit un ghid interactiv cum poți să deschizi containerul folosind telefonul mobil sau cardul primit de la Primărie sau Asociația de proprietari."
  ></TutorialLayout>
)

export default Start
